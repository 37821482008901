import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { FileService } from './file.service';
import { HostnameService } from './hostname.service';

@Injectable({ providedIn: 'root' })
export class AccountAvatarService {
    private _avatarLink: string = '';
    private _avatarLinkSub: Subject<string> = new Subject<string>();

    public get AvatarLink() {
        return this._avatarLink;
    }

    public set AvatarLink(value: string) {
        this._avatarLink = value;
        this._avatarLinkSub.next(value);
    }

    public get AvatarLinkSub() {
        return this._avatarLinkSub;
    }

    constructor(
        private httpClient: HttpClient,
        private hostnameService: HostnameService,
        protected readonly keycloak: KeycloakService,
        private accountService: AccountService,
        private fileService: FileService
    ) {}

    updateUserAvatar(file: Blob) {
        let authMetadata = this.accountService.getMetadata();

        let formData = new FormData();
        formData.append('file', file);
        formData.append('_id', this.accountService.subject);
        formData.append('ma_don_vi', authMetadata.ma_don_vi);
        formData.append('mat_khau_don_vi', authMetadata.mat_khau_don_vi);

        return this.httpClient
            .post<any>(this.hostnameService.VnuisAPI_Stagingv1 + '/account-info/change-avatar', formData, {
                headers: {
                    apikey: this.accountService.apikey,
                },
            })
            .toPromise();
    }

    async updateAvatarLink() {
        let filename: string = 'avatar_' + this.accountService.subject + '.png';
        let link = await this.fileService.getPresignded(filename, 'images', 5);
        if (link == 'Error: File type not exists' || link == 'Error: File name not exists' || link == 'FAIL') link = '';
        this.AvatarLink = link;
    }
}
