import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

@Injectable({ providedIn: 'root' })
export class FileService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async getPresignded(filename: string, filetype: string, minutes: any) {
        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI_Readv1 + '/files/get-presigned?apikey=' +
                        this.accountService.apikey,
                    {
                        filename: filename,
                        filetype: filetype,
                        minutes: minutes,
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
}
