import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';
import { AccountService } from 'src/app/providers/account.service';
export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface SubChildren {
    state: string;
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    child?: SubChildren[];
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildrenItems[];
}
var checkroles: Routes = [];

export const HOC_BONG = {
    state: 'scholarship',
    name: 'scholarship_management',
    type: 'sub',
    icon: 'school',
    children: [
        { state: 'scholarship-list', name: 'scholarship_list', type: 'link' },
        { state: 'scholarship-registration', name: 'scholarship_registration', type: 'link' },
    ],
};

export const UNG_VIEN = {
    state: 'candidate',
    name: 'candidate_management',
    type: 'sub',
    icon: 'person',
    children: [
        { state: 'candidate-list', name: 'candidate_list', type: 'link' },
        // { state: 'candidate-registration', name: 'candidate_registration', type: 'link' },
        { state: 'candidate-evaluate', name: 'candidate_evaluate', type: 'link' },
    ],
};

export const UNG_VIEN_DON_VI = {
    state: 'candidate',
    name: 'candidate_management',
    type: 'sub',
    icon: 'person',
    children: [
        { state: 'candidate-list', name: 'candidate_list', type: 'link' },
        { state: 'candidate-registration', name: 'candidate_registration', type: 'link' },
    ],
};

export const NHA_TAI_TRO = {
    state: 'sponsor',
    name: 'sponsor_management',
    type: 'sub',
    icon: 'work',
    children: [
        { state: 'sponsor-list', name: 'sponsor_list', type: 'link' },
        { state: 'sponsor-registration', name: 'sponsor_registration', type: 'link' },
    ],
};

// export const TIEU_CHI_DANH_GIA = {
//     state: 'evaluation',
//     name: 'evaluation_management',
//     type: 'sub',
//     icon: 'ballot',
//     children: [
//         { state: 'evaluation-list', name: 'evaluation_list', type: 'link' },
//         { state: 'evaluation-registration', name: 'evaluation_registration', type: 'link' },
//     ],
// };

export const BAO_CAO_TONG_HOP = {
    state: 'synthetic',
    name: 'Reporting',
    type: 'sub',
    icon: 'library_books',
    children: [
        // { state: 'annualreport', name: 'Annual Report', type: 'link' },
        // { state: "reportoffice", name: "VNUHCM Office", type: "link" },
        // { state: "reportttpc", name: "Report TTPC", type: "link" },
        // { state: "reporttdkt", name: "Report TDKT", type: "link" },
        // { state: "reportstafforganizition", name: "Staff Organization Dept.", type: "link" },
        // { state: "reportsdh", name: "Report SDH", type: "link" },
        // { state: "reportkhtc", name: "Report KHTC", type: "link" },
        // { state: 'reportkhcn', name: 'Report KHCN', type: 'link' },
        // { state: "reportptda", name: "Report PTDA", type: "link" },
        // { state: "reportdh", name: "Report DH", type: "link" },
        // { state: "reportctsv", name: "Report CTSV", type: "link" },

        // { state: 'bao-cao-tn', name: 'bao_cao_thuong_nien', type: 'link' },
        // { state: 'bao-cao-van-phong', name: 'bao_cao_van_phong', type: 'link' },
        { state: 'bao-cao-ban-ctsv', name: 'bao_cao_ban_ctsv', type: 'link' },
    ],
};

export const TAI_KHOAN = {
    state: 'account',
    name: 'Accounts',
    type: 'sub',
    icon: 'account_circle',
    children: [{ state: 'editaccount', name: 'Edit Account', type: 'link' }],
};

export const DASHBOARD = {
    state: 'dashboards',
    name: 'Dashboards',
    type: 'link',
    icon: 'av_timer',
};

export const YEU_CAU_DSUV = {
    state: 'request-candidate-list',
    name: 'Request Candidate List',
    type: 'sub',
    icon: 'account_circle',
    children: [
        { state: 'list', name: 'List', type: 'link' },
        { state: 'registration', name: 'Registration', type: 'link' },
        { state: 'dhqg-approved', name: 'Approved', type: 'link' },
        // { state: 'dhqg-approved-list', name: 'Approved List', type: 'link' },
    ],
};

export const YEU_CAU_DSUV_CTSV = {
    state: 'request-candidate-list',
    name: 'Request Candidate List',
    type: 'sub',
    icon: 'account_circle',
    children: [
        { state: 'list', name: 'List', type: 'link' },
        { state: 'ctsv-approved', name: 'Approved', type: 'link' },
    ],
};

export const YEU_CAU_DSUV_DVTV = {
    state: 'request-candidate-list',
    name: 'Request Candidate List',
    type: 'sub',
    icon: 'account_circle',
    children: [
        { state: 'list', name: 'List', type: 'link' },
        { state: 'answer', name: 'Answer', type: 'link' },
    ],
};

export const QUAN_LY_EMAIL_DHQG = {
    state: 'email-management',
    name: 'Email Management',
    type: 'sub',
    icon: 'mail',
    children: [
        { state: 'list', name: 'List', type: 'link' },
        { state: 'list-auto', name: 'List Auto', type: 'link' },
        { state: 'email-dvtv', name: 'Email Dvtv', type: 'link' },
    ],
};

export const QUAN_LY_EMAIL_CTSV = {
    state: 'email-ctsv-management',
    name: 'Email Management',
    type: 'sub',
    icon: 'mail',
    children: [
        { state: 'list', name: 'List', type: 'link' },
        { state: 'list-auto', name: 'List Auto', type: 'link' },
    ],
};

// Files
export const FILES = {
    state: 'files',
    name: 'Files',
    type: 'sub',
    icon: 'archive',
    children: [{ state: 'filesdownloaded', name: 'Files Downloaded', type: 'link' }],
};

// Surveys
export const KHAO_SAT_MENUITEMS = {
    state: 'khao-sat',
    name: 'khao_sat.menu_items.khao_sat',
    type: 'sub',
    icon: 'format_align_left',
    children: [
        { state: 'danh-sach', name: 'khao_sat.menu_items.danh_sach_khao_sat', type: 'link' },
        { state: 'quan-ly', name: 'khao_sat.menu_items.quan_ly_khao_sat', type: 'link' },
        { state: 'thong-ke', name: 'khao_sat.menu_items.thong_ke_khao_sat', type: 'link' },
    ],
};

export const MENUITEMS = [
    DASHBOARD,

    // //////////////////////////
    // // scholarship
    // HOC_BONG,

    // // candidate
    // UNG_VIEN,

    // // sponsor
    // NHA_TAI_TRO,

    // // // evaluation
    // // TIEU_CHI_DANH_GIA,

    // YEU_CAU_DSUV,

    BAO_CAO_TONG_HOP,

    // KHAO_SAT_MENUITEMS,

    TAI_KHOAN,
];

@Injectable({ providedIn: 'root' })
export class MenuItems {
    constructor(private accountService: AccountService) {}

    getMenuitem(): Menu[] {
        let userRole = this.accountService.getRoles();

        let menuItems: any = [];

        if (userRole.includes('ctsv_dhqg')) {
            menuItems = [
                DASHBOARD,
                HOC_BONG,
                UNG_VIEN,
                NHA_TAI_TRO,
                YEU_CAU_DSUV,
                QUAN_LY_EMAIL_DHQG,
                BAO_CAO_TONG_HOP,
                KHAO_SAT_MENUITEMS,
            ];
        } else if (userRole.includes('ctsv')) {
            menuItems = [DASHBOARD, YEU_CAU_DSUV_CTSV, QUAN_LY_EMAIL_CTSV, BAO_CAO_TONG_HOP, KHAO_SAT_MENUITEMS];
        } else if (userRole.includes('ctsv_ntt')) {
            menuItems = [HOC_BONG];
        } else if (userRole.includes('ctsv_dvtv')) {
            menuItems = [DASHBOARD, YEU_CAU_DSUV_DVTV];
        }

        menuItems.push(FILES);
        menuItems.push(TAI_KHOAN);

        return menuItems;
    }
}

export function updateMenuItemsRole() {}
