import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './helper/auth.guard';

export const AppRoutes: Routes = [
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        data: { someRoles: ['ctsv_dhqg', 'ctsv', 'ctsv_ntt', 'ctsv_dvtv'] },
        children: [
            {
                path: '',
                redirectTo: '/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                redirectTo: '/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'dashboards',
                data: { someRoles: ['ctsv_dhqg', 'ctsv', 'ctsv_ntt', 'ctsv_dvtv'] },
                loadChildren: () => import('./dashboards/dashboards.module').then((m) => m.DashboardsModule),
            },
            {
                path: 'report-chart',
                redirectTo: '/report-chart',
                pathMatch: 'full',
            },

            // {
            //     path: 'synthetic',
            //     data: { someRoles: ['ctsv_dhqg', 'ctsv_dvtt'] },
            //     canActivate: [AuthGuard],
            //     loadChildren: () => import('./synthetic/synthetic.module').then((m) => m.SyntheticModule),
            //     // canActivate: [AuthGuard],
            // },

            {
                path: 'account',
                loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
                // canActivate: [AuthGuard],
            },

            {
                path: 'candidate',
                data: { someRoles: ['ctsv_dhqg', 'ctsv_dvtt', 'ctsv'] },
                canActivate: [AuthGuard],
                loadChildren: () => import('./ctsv/candidate/candidate-management.module').then((m) => m.FormModule),
            },
            // {
            //     path: 'evaluation',
            //     // data: { roles: ['admin','ctsv_dhqg','ctsv_dvtt'] },
            //     // canActivate: [AuthGuard],
            //     loadChildren: () =>
            //         import('./ctsv/evaluation-scholarship/evaluation-scholarship.module').then((m) => m.FormModule),
            // },
            {
                path: 'scholarship',
                data: { someRoles: ['ctsv_dhqg', 'ctsv_ntt'] },
                canActivate: [AuthGuard],
                loadChildren: () => import('./ctsv/scholarship/scholarship.module').then((m) => m.FormModule),
            },
            {
                path: 'sponsor',
                data: { everyRoles: ['ctsv_dhqg'] },
                canActivate: [AuthGuard],
                loadChildren: () => import('./ctsv/sponsor/sponsor.module').then((m) => m.FormModule),
            },

            {
                path: 'request-candidate-list',
                data: { someRoles: ['ctsv_dhqg', 'ctsv', 'ctsv_dvtv'] },
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./ctsv/management-scholarship-candidates/module').then((m) => m.RequestCandidateListModule),
            },

            {
                path: 'email-management',
                data: { everyRoles: ['ctsv_dhqg'] },
                canActivate: [AuthGuard],
                loadChildren: () => import('./ctsv/email-management/module').then((m) => m.FormModule),
            },
            {
                path: 'email-ctsv-management',
                data: { everyRoles: ['ctsv'] },
                canActivate: [AuthGuard],
                loadChildren: () => import('./ctsv/email-ctsv-management/module').then((m) => m.FormModule),
            },

            // Synthetic
            {
                path: 'synthetic',
                data: { everyRoles: ['ctsv_dhqg'] },
                loadChildren: () => import('./synthetic/synthetic.module').then((m) => m.SyntheticModule),
            },

            // Files
            {
                path: 'files',
                loadChildren: () => import('./files/files.module').then((m) => m.FilesModule),
            },

            // Surveys
            {
                path: 'khao-sat',
                loadChildren: () => import('./khao_sat/khao_sat.module').then((m) => m.KhaoSatModule),
            },
        ],
    },
    {
        path: '',
        component: AppBlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren: () =>
                    import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'authentication/404',
    },
];
