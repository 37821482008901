<button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5">
    <i class="flag-icon flag-icon-{{ selectedLanguage.icon }}"> </i>
</button>
<mat-menu #flags="matMenu" class="mymegamenu">
    <button mat-menu-item *ngFor="let lang of languages" (click)="changeLanguage(lang)">
        <i class="flag-icon flag-icon-{{ lang.icon }} m-r-10"></i>
        <span>{{ lang.language }}</span>
    </button>
</mat-menu>

<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
    <img [src]="avatarLink" alt="user" class="profile-pic" />
</button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item (click)="accountInfo()">
        <mat-icon>account_box</mat-icon> {{ 'Thông tin tài khoản' | translate }}
    </button>
    <button mat-menu-item (click)="logout()"><mat-icon>exit_to_app</mat-icon> {{ 'Đăng xuất' | translate }}</button>
</mat-menu>

<!-- <button (click)="logout()" mat-icon-button class="m-r-5">
    <mat-icon>logout</mat-icon>
</button> -->
