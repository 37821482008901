import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

@Injectable({ providedIn: 'root' })
export class OrgDataService {
    constructor(
        private accountService: AccountService,
        private httpClient: HttpClient,
        private hostnameService: HostnameService
    ) {}

    async getAllByParrentCodes(codes: string[]) {
        let metadata = this.accountService.getMetadata();
        let apikey = this.accountService.apikey;
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Readv1 + '/org-data/get-all-by-parent_codes',
                {
                    metadata: {
                        ma_don_vi: metadata.ma_don_vi,
                        mat_khau_don_vi: metadata.mat_khau_don_vi,
                    },
                    codes: codes,
                },
                {
                    headers: { apikey: apikey },
                }
            )
            .toPromise();
    }
}
