import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguageService {
    private _language: string = 'vn';

    private _languageSub: Subject<string> = new Subject<string>();

    public get language(): string {
        return this._language;
    }
    public set language(value: string) {
        this._language = value;
        this._languageSub.next(value);
    }
    public get languageSub(): Subject<string> {
        return this._languageSub;
    }

    getLanguage() {
        return this._language;
    }

    getLanguageUpdateListener() {
        return this._languageSub.asObservable();
    }

    setLanguage(newLanguage: string) {
        this._language = newLanguage;

        this._languageSub.next(this.getLanguage());
    }
}
